import React from "react"
import "./layout.css"

export default function Layout({ children }) {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  return <div>{children}</div>
}
